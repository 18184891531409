import { IndexContext } from '@/src/pages'
import classNames from 'classnames'
import { memo, useContext } from 'react'
import { Container } from 'react-bootstrap'
import useI18n from 'src/hooks/use_i18n'
import GaDecoratorBlock from './GaDecorator'
import RowTableDom from './RowTableDom'
import styles from './index.module.scss'

function LogisticsRecommend() {
  const { $t, activeLocale: locale } = useI18n()
  const { matchHotCarriers } = useContext(IndexContext)
  const handleVisible = (id: string) => {
    // 在这里发送曝光量统计
    window.ga?.sendGaEvent(`Brand_${id}`, {
      event_category: '品牌标签',
      event_action: `曝光`
    })
  }

  // 处理合作运输商点击上报事件
  const handleCarrierClick = (name: string) => {
    window.ga?.sendGaEvent(`Brand_name`, {
      event_category: '品牌标签',
      event_action: '曝光',
      value: name
    })
  }

  return (
    <div className={styles['logistics_recommend']}>
      <div className={styles['panel-coop']}>
        <svg
          width="100%"
          height="100"
          viewBox="0 0 1440 80"
          preserveAspectRatio="xMidYMax slice"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <polygon points="0 0 1440 0 1440 14 720 80 0 14" fill="#fff"></polygon>
        </svg>
        <section data-animation-in>
          <h2 className={classNames(styles.title)} data-animation-in>
            {$t('ResWwwPHome.global.__partner_title')}
          </h2>
          <p className={classNames(styles.desc)} data-animation-in>
            {$t('ResWwwPHome.global.__partner_desc')}
          </p>
          <div className={styles['coop-container']}>
            <Container>
              <GaDecoratorBlock id="level1" onVisible={handleVisible}>
                <RowTableDom
                  mdSize={4}
                  smSize={4}
                  row={1}
                  xsSize={12}
                  carriers={matchHotCarriers?.slice(0, 3)}
                  onCarrierClick={handleCarrierClick}
                />
              </GaDecoratorBlock>
              <GaDecoratorBlock id="level2" onVisible={handleVisible}>
                <RowTableDom
                  mdSize={3}
                  smSize={6}
                  row={2}
                  xsSize={12}
                  carriers={matchHotCarriers?.slice(3, 7)}
                  onCarrierClick={handleCarrierClick}
                />
              </GaDecoratorBlock>
              <GaDecoratorBlock id="level3" onVisible={handleVisible}>
                <RowTableDom
                  mdSize={2}
                  smSize={4}
                  row={3}
                  xsSize={12}
                  carriers={matchHotCarriers?.slice(7)}
                  onCarrierClick={handleCarrierClick}
                />
              </GaDecoratorBlock>
            </Container>
            <a
              id="link-all-carrier"
              className={classNames(styles['view-all'])}
              href={`/${locale}/carriers`}
              data-animation-in
            >
              {$t('ResWwwHomePage.global.__partner_carrierlistBtn')} &gt;&gt;
            </a>
          </div>
        </section>
        <svg
          width="100%"
          height="100"
          viewBox="0 0 1440 80"
          preserveAspectRatio="xMidYMin slice"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="WWW" stroke="none" strokeWidth="1" fill="#fff" fillRule="evenodd">
            <polygon points="0 80 1440 80 1440 66 720 0 0 66"></polygon>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default memo(LogisticsRecommend)
