import { cooperationPartner } from '@/config/carriers/carriersConfig'
import React, { useEffect } from 'react'
import Feature from 'src/components/index/feature'
import FreeApply from 'src/components/index/free_apply'
import LogisticsRecommend from 'src/components/index/logistics_recommend'
import MultiScenario from 'src/components/index/multi_scenario'
import Layout from 'src/components/layout'
import LinkSeo from 'src/components/layout/linkSeo'
import MetaSeo from 'src/components/layout/metaSeo'
import Tracking from 'src/components/tracking'
import { getCnCoopCarriers } from 'src/constants/coop_carriers'
import useAnimation from 'src/hooks/use_animation'
import useGA from 'src/hooks/use_ga'
import useI18n from 'src/hooks/use_i18n'
import useRedirectSite from 'src/hooks/use_redirect_site'
import { CarrierInfoMarkDTO, IKV } from 'src/types'
import { carrierInfoMarkMap } from 'src/utils/carriers'
import { getArrayByCountry, queryCarrierByKey } from 'src/utils/carriers_info'
import { getV5Front } from 'src/utils/server_global'
import { DEFAULT_CARRIER_INFO_MARK } from '../constants/carrier_info_mark'
const logger = require('@/config/logger')

interface Props {
  carrierInfoMark: CarrierInfoMarkDTO
  originalCarrierInfoMark: CarrierInfoMarkDTO
  country: string | any
  matchHotCarriers: IKV[]
}

export const IndexContext = React.createContext<{
  carrierInfoMark: CarrierInfoMarkDTO
  country: string | any
  matchHotCarriers: IKV[]
}>({
  carrierInfoMark: {
    ranking: '',
    partner: '',
    airwaybill: '',
    user: '',
    country: '',
    language: '',
    appInstalled: '',
    cumulativeTracking: ''
  },
  country: '',
  matchHotCarriers: []
})

export default function Index({ carrierInfoMark, originalCarrierInfoMark, country, matchHotCarriers }: Props) {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()
  useGA('index')
  useRedirectSite()
  useAnimation()

  useEffect(() => {
    const AD = require('@/plugins/ad_adapter/index.js')
    AD?.(country) // 广告插入
  }, [])

  return (
    <>
      <MetaSeo
        title={
          locale == 'en'
            ? 'All-in-One Global Package Tracking' + ' | 17TRACK'
            : $t('ResWWWAndM.global.__seoTitle') + ' | 17TRACK'
        }
        description={
          locale == 'en'
            ? i18nFormat(
                '17TRACK supports tracking for {0} carriers, including USPS, UPS, FedEx, and DHL. Enter your tracking number to get 99.9% accuracy and real-time updates.',
                [i18nFormat($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner])]
              )
            : $t('ResWWWAndM.global.__seoDesc')
        }
        keywords={
          locale == 'en'
            ? [
                'tracking',
                'tracking number',
                'track package',
                'package tracking',
                'track my package',
                'track a package',
                'package tracker',
                'parcel tracking'
              ]
            : []
        }
      ></MetaSeo>
      {/* <Head> */}
      {/* google ads初始化 */}
      {/* <InitializeGPT defaultAdp /> */}
      {/* <LoadAdScript></LoadAdScript> */}
      {/* </Head> */}
      <LinkSeo TagType="onPage" routeLang={locale} />
      <Layout showAllPlatform carrierInfoMark={originalCarrierInfoMark} country={country}>
        <IndexContext.Provider value={{ carrierInfoMark, country, matchHotCarriers }}>
          <Tracking />
          <LogisticsRecommend />
          <MultiScenario />
          <Feature />
          <FreeApply />
        </IndexContext.Provider>
      </Layout>
    </>
  )
}

export async function getServerSideProps({ req, locale }) {
  try {
    logger.info('indexHeaders信息', { headers: req.headers })
    const { 'cf-ipcountry': country = 'CN' } = req.headers
    const [V5Front, carrierInfoMark, allCarriersJson] = await Promise.all([
      getV5Front('www', locale),
      fetch(`https://res.17track.net/asset/carrier/info/mark.${locale}.json`)
        .then((res) => res.json())
        .catch((error) => {
          logger.error('index页面获取carrierInfoMark信息异常', { message: error.message })
          return DEFAULT_CARRIER_INFO_MARK // 兜底数据
        }),
      fetch(`https://res.17track.net/asset/carrier/info/carrier.all.json`)
        .then((res) => res.json())
        .catch((error) => {
          logger.error('carriers页面获取allCarriersJson数据异常', { message: error.message })
          return [] // 兜底无数据
        })
    ])

    logger.info('获取carrierInfoMark信息', { carrierInfoMark })
    // 将特殊合作商数组与运输商数组合并
    const specialCoopCarriers = allCarriersJson?.concat(Object.values(cooperationPartner))
    // 根据国家匹配不同的热门运输商数组
    let matchHotCarriers: any = []
    if (country?.toLowerCase() === 'cn') {
      // 获取中国地区品牌合作标签
      matchHotCarriers = getCnCoopCarriers()
    } else {
      // 其他国家走其他配置
      matchHotCarriers = queryCarrierByKey(specialCoopCarriers, getArrayByCountry(country))
    }
    return {
      props: {
        locale,
        V5Front,
        country,
        originalCarrierInfoMark: carrierInfoMark,
        carrierInfoMark: carrierInfoMarkMap(carrierInfoMark),
        matchHotCarriers
      }
    }
  } catch (error: any) {
    logger.error('index页面请求异常', { message: error.message })
  }
}
