import classNames from 'classnames'
import getConfig from 'next/config'
import Image from 'next/image'
import { memo, useContext } from 'react'
import { Else, If, Then } from 'react-if'
import CustomImage from 'src/components/Image'
import useAppStore from 'src/hooks/business/use_app_store'
import useI18n from 'src/hooks/use_i18n'
import { IndexContext } from 'src/pages'
import styles from './index.module.scss'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

function MultiScenario() {
  const { $t, activeLocale: locale, i18nFormat } = useI18n()
  const { carrierInfoMark } = useContext(IndexContext)
  const { isForeign, stores } = useAppStore()

  const handleClick = () => {
    // 谷歌广告转化
    window.ga?.sendGaEvent?.('conversion', {
      send_to: 'AW-16826827968/qshGCKL74IwaEMCJ1Nc-' // 谷歌广告转化ID
    })
  }

  const handleAppClick = (link: string) => {
    if (link?.includes('apple')) {
      return window.ga?.sendEvent?.('事件操作', `index-跳转app store`)
    }
    if (link?.includes('google')) {
      return window.ga?.sendEvent?.('事件操作', `index-跳转google play`)
    }

    return window.ga?.sendEvent?.('事件操作', `index-download apk`)
  }

  return (
    <section className={styles['multi-scenario']}>
      <h2 className={classNames(styles.title)} data-animation-in>
        {$t('ResWwwPHome.global.__introduction_title')}
      </h2>
      <ul>
        <li>
          <div className={styles['shopify-service']}>
            <Image
              width={72}
              height={72}
              src={assetPrefix + '/assets/images/appdown/shopify_logo.svg'}
              alt="shopify logo"
            />
            <h3 className={classNames(styles['info-title'])} data-animation-in>
              Shopify App
              <Image
                width={231}
                height={25}
                src={assetPrefix + '/assets/images/appdown/build_for_shopify.svg'}
                alt="build for shopify"
              />
            </h3>
            <p className={classNames(styles['info-desc'])} data-animation-in>
              {i18nFormat($t('ResWwwHomePage.global.__shopifyProduct_desc'), [carrierInfoMark?.partner])}
            </p>
            <a
              href={`//apps.shopify.com/17track?utm_source=www.17track.net&utm_medium=link&utm_campaign=newenter`}
              target="_blank"
              onClick={handleClick}
              className={classNames(styles['info-link'])}
              data-animation-in
            >
              {$t('ResWwwHomePage.global.__shopifyProduct_btn')}
            </a>
          </div>
        </li>
        <li>
          <div data-animation-in className="relative">
            <CustomImage
              src={assetPrefix + '/assets/images/track_page/part3_01'}
              alt="17TRACK"
              className="lazy-image-fill"
              fill
            />
          </div>
        </li>
        <li className="hidden-xs">
          <div data-animation-in>
            <CustomImage
              src={assetPrefix + '/assets/images/api_service/illustration'}
              alt="17TRACK"
              className="lazy-image-fill"
              fill
            />
          </div>
        </li>
        <li>
          <div className={styles['api-service']}>
            <Image
              src={assetPrefix + '/assets/images/api_service/logo.svg'}
              alt="17TRACK"
              className={classNames(styles['info-logo'])}
              data-animation-in
              width={72}
              height={72}
            />
            <h3 className={classNames(styles['info-title'])} data-animation-in>
              {$t('ResWwwPHome.global.__apiProduct_title')}
            </h3>
            <p className={classNames(styles['info-desc'])} data-animation-in>
              {i18nFormat($t('ResWwwHomePage.global.__apiProduct_desc'), [carrierInfoMark?.partner])}
            </p>
            <a
              href={`//www.17track.net/${locale}/api`}
              target="_blank"
              className={classNames(styles['info-link'])}
              data-animation-in
            >
              {$t('ResWwwPHome.global.__apiProduct_linkBtn')} &gt;&gt;
            </a>
          </div>
        </li>
        <li className="hide visible-xs">
          <div data-animation-in>
            <CustomImage
              src={assetPrefix + '/assets/images/api_service/illustration'}
              alt="17TRACK"
              className="lazy-image-fill"
              fill
            />
          </div>
        </li>
        {/* <li className="hidden-xs">
          <div data-animation-in className="relative">
            <CustomImage src={assetPrefix + '/assets/images/appdown/illustration'} alt="17TRACK" className="lazy-image-fill" fill />
          </div>
        </li> */}
        <li>
          <div className={styles['api-service']}>
            <Image
              src={assetPrefix + '/assets/images/appdown/trackapp.svg'}
              alt="17TRACK"
              className={classNames('relative', styles['info-logo'])}
              data-animation-in
              width={72}
              height={72}
            />
            <h3 className={classNames(styles['info-title'])} data-animation-in>
              {$t('ResWwwPHome.global.__appProduct_title')}
              <span className={styles.badge}>{$t('ResWwwPHome.global.__appProduct_freeTitle')}</span>
            </h3>
            <p className={classNames(styles['info-desc'])} data-animation-in>
              {i18nFormat($t('ResWwwHomePage.global.__appProduct_desc'), [carrierInfoMark?.user])}
            </p>
            <If condition={isForeign}>
              <Then>
                <div className={classNames(styles['app-download'])}>
                  {stores.map((ele) => (
                    <a
                      href={ele.link}
                      key={ele.icon}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleAppClick.bind(null, ele.link)}
                    >
                      <Image
                        src={`${assetPrefix}/assets/images/appdown/${ele.icon}`}
                        key={ele.icon}
                        alt="17TRACK"
                        width={122}
                        height={40}
                      />
                    </a>
                  ))}
                </div>
              </Then>
              <Else>
                <div className={styles['qrcode-wrapper']}>
                  <CustomImage
                    src={assetPrefix + '/assets/images/appdown/qrcode'}
                    alt="17TRACK"
                    className={styles['qr-code']}
                    width={180}
                    height={208}
                  />
                  <div className={classNames(styles['app-download'], 'flex-col ml-5')}>
                    {stores.map((ele) => (
                      <a
                        href={ele.link}
                        key={ele.icon}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleAppClick.bind(null, ele.link)}
                      >
                        <Image
                          src={`${assetPrefix}/assets/images/appdown/${ele.icon}`}
                          key={ele.icon}
                          alt="17TRACK"
                          width={122}
                          height={40}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </Else>
            </If>
          </div>
        </li>

        {/* xs */}
        <li className="relative">
          <div data-animation-in className="relative">
            <CustomImage
              src={assetPrefix + '/assets/images/appdown/illustration'}
              alt="17TRACK"
              fill
              className="lazy-image-fill"
            />
          </div>
        </li>
      </ul>
    </section>
  )
}

export default memo(MultiScenario)
