import { useEffect, useRef } from 'react'

export default function useAnimation() {
  const coopCarriersInfo = useRef<Record<'isScroll' | 'isShowBrandLabel', boolean>>({
    isScroll: false,
    isShowBrandLabel: false
  }) // 合作运输商曝光埋点上报

  useEffect(() => {
    const animations = document.querySelectorAll('[data-animation-in]')
    // const $linkAllCarrier = document.querySelector('#link-all-carrier') as HTMLDivElement

    const handler = () => {
      try {
        animations?.forEach((element) => {
          const bounding = element.getBoundingClientRect()
          if (
            bounding.top < window.innerHeight &&
            bounding.bottom > 0 &&
            !element.classList.contains('fade-from-bottom')
          ) {
            element.classList.add('fade-from-bottom')
          }
        })

        // if ($linkAllCarrier.classList.contains('fade-from-bottom') && !coopCarriersInfo.current.isShowBrandLabel) {
        //   window.ga?.sendEvent?.('功能操作', '首页-品牌标签展示', window.YQ.WWW.CoopCarrierItem)
        //   coopCarriersInfo.current.isShowBrandLabel = true
        // }

        if (!coopCarriersInfo.current.isScroll) {
          window.ga?.sendEvent?.('功能操作', '首页-页面下拉', '滚动条触发')
          coopCarriersInfo.current.isScroll = true
        }
      } catch (error) {
        console.log(error)
      }
    }

    window.addEventListener('scroll', handler)

    window.dispatchEvent(new Event('scroll'))

    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])
}
