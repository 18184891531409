import classNames from 'classnames'
import getConfig from 'next/config'
import Image from 'next/image'
import { memo, useContext, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import useCarrierBanner from 'src/hooks/business/use_carrier_banner'
import useI18n from 'src/hooks/use_i18n'
import { IndexContext } from 'src/pages'
import { filterCarriersName } from 'src/utils/carriers_info'
import styles from './index.module.scss'

const { publicRuntimeConfig } = getConfig()

const assetPrefix = publicRuntimeConfig.assetPrefix || ''

function Feature() {
  const { $t, i18nFormat, activeLocale: locale } = useI18n()
  const { carrierInfoMark } = useContext(IndexContext)
  const { bannerList } = useCarrierBanner()

  const featureList = useMemo(() => {
    const items = $t('ResWwwHomePage.items').map((ele, idx) => {
      if (ele.key === '2') {
        ele._name = i18nFormat(ele._name, [carrierInfoMark?.partner])
        ele._desc = i18nFormat(ele._desc, [carrierInfoMark?.partner])
      }
      return { title: ele._name, desc: ele._desc, icon: `wp-service-0${idx + 1}` }
    })

    return items
  }, [carrierInfoMark])

  return (
    <section className={styles.feature}>
      <div className={styles.intro}>
        <h2 className={classNames(styles['title'])} data-animation-in>
          {$t('ResWwwPHome.global.__service_title')}
        </h2>
        <p className={classNames(styles['desc'])} data-animation-in>
          {$t('ResWwwPHome.global.__service_desc')}
        </p>
        <Container>
          <Row>
            {featureList.map((ele) => (
              <Col key={ele.title} lg={4} md={6} xs={12}>
                <div className={styles['feature-item']}>
                  <div className={classNames(styles.lightspot)}>
                    <div className={classNames(styles.info)}>
                      <h3 data-animation-in>{ele.title}</h3>
                      <p data-animation-in>{ele.desc}</p>
                    </div>
                    <div
                      className={classNames(ele.icon, styles['mask-layer'])}
                      style={{ backgroundImage: `url(${assetPrefix}/assets/images/sprite.png)` }}
                      data-animation-in
                    ></div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={styles.supported}>
        <h2 className={classNames(styles['title'])} data-animation-in>
          {i18nFormat($t('ResWwwPHome.global.__score_title'), [carrierInfoMark?.partner])}
        </h2>
        <p className={classNames(styles['desc'])} data-animation-in>
          {i18nFormat($t('ResWwwPHome.global.__score_desc'), [carrierInfoMark?.partner])}
        </p>
        <div className={styles.list}>
          <Container>
            <Row className="flex items-center">
              <Col xs={12} className={classNames('visible-md', styles.col)}>
                <div className={classNames(styles['supported-item'], styles.main)}>
                  <div>
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_totalTrackNumVar'), [
                        carrierInfoMark?.cumulativeTracking
                      ])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_totalTrack')}</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <div className={styles['supported-item']}>
                  <div>
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.partner])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_coopCarrier')}</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <div className={styles['supported-item']}>
                  <div>
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_registerUserNumVar'), [carrierInfoMark?.user])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_registerUser')}</p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={6} xs={6} className="hidden-md">
                <div className={classNames(styles['supported-item'], styles.main)}>
                  <div>
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_totalTrackNumVar'), [
                        carrierInfoMark?.cumulativeTracking
                      ])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_totalTrack')}</p>
                  </div>
                </div>
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <div className={styles['supported-item']}>
                  <div>
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.country])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_coveringCountries')}</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <div className={styles['supported-item']}>
                  <div className="">
                    <h3 data-animation-in>
                      {i18nFormat($t('ResWwwPHome.global.__score_totalVar'), [carrierInfoMark?.language])}
                    </h3>
                    <p data-animation-in>{$t('ResWwwPHome.global.__score_supportLang')}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className={styles['brand-wrapper']}>
        <ul className={classNames(styles['brand-list'])}>
          {/* 循环叠加轮播 */}
          {bannerList.map((ele) => (
            <li key={ele.key}>
              <a href={`/${locale}/carriers/${filterCarriersName(ele._name)}`}>
                <Image
                  alt={ele?._name}
                  src={`https://res.17track.net/asset/carrier/logo/120x120/${ele.key}.png`}
                  width={120}
                  height={120}
                />
              </a>
            </li>
          ))}
          {bannerList.map((ele) => (
            <li key={ele.key}>
              <a href={`/${locale}/carriers/${filterCarriersName(ele._name)}`}>
                <Image
                  alt={ele?._name}
                  src={`https://res.17track.net/asset/carrier/logo/120x120/${ele.key}.png`}
                  width={120}
                  height={120}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={styles['advantage-bg']}
        style={{ backgroundImage: `url(${assetPrefix}/assets/images/advantage_bg.png)` }}
      ></div>
    </section>
  )
}

export default memo(Feature)
