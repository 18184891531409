import { useClickAway } from 'ahooks'
import classNames from 'classnames'
import { memo, useContext, useEffect, useRef, useState } from 'react'
import { Case, Switch, When } from 'react-if'
import useI18n from 'src/hooks/use_i18n'
import { storage } from 'src/lib/storage'
import { IndexContext } from 'src/pages'
import BatchTrack from '../batch_track'
import styles from './index.module.scss'

// const ADS_CONFIG = {
//   adUnit: '/' + ADID + '/V6-WWW',
//   divId: 'DA_V6-WWW',
//   interval: 240,
//   size: [
//     [728, 90],
//     [468, 60],
//     [320, 50]
//   ],
//   mapping: [
//     {
//       viewSize: [768, 100],
//       slotSize: [728, 90]
//     },
//     {
//       viewSize: [468, 100],
//       slotSize: [468, 60]
//     },
//     {
//       viewSize: [320, 100],
//       slotSize: [320, 50]
//     },
//     {
//       viewSize: [0, 0],
//       slotSize: []
//     }
//   ]
// }

type Props = {
  carrierInfoMark?: any
  title?: string
  desc?: string
  showAd?: boolean
}

function Tracking({ carrierInfoMark: _carrierInfoMark, title, desc, showAd = true }: Props) {
  const { $t, i18nFormat, activeLocale: locale } = useI18n()
  const { carrierInfoMark } = useContext(IndexContext)
  const carriers = _carrierInfoMark || carrierInfoMark
  const ref = useRef<HTMLDivElement | null>(null)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [trackHistory, setTrackHistory] = useState<string[]>([]) // 历史查单记录

  const dropdownRef = useRef<HTMLDivElement>(null)
  // 英文环境查询框自由度placehodler
  const placeholder =
    locale === 'en'
      ? `Enter up to 40 tracking numbers, one per line; \n Supports airline cargo tracking (e.g. 123-12345678).`
      : ''

  useClickAway(() => setShowMore(false), dropdownRef)

  useEffect(() => {
    try {
      const result = storage.get('v5_HisNumber') || []
      setTrackHistory(result.filter((ele) => ele.trim()))
    } catch (error) {
      storage.set('v5_HisNumber', [])
    }
  }, [])

  // 选择历史查询运单
  const handleSelHistoryTrack = (e, ele) => {
    e.preventDefault()
    e.stopPropagation()
    window.ga?.sendEvent?.('功能操作', '首页-点击上次历史查询')
    location.href = `//t.17track.net#nums=${ele}`
  }

  // 删除
  const handleDel = (e, num: string) => {
    e.preventDefault()
    e.stopPropagation()
    const _trackHistory = storage.get('v5_HisNumber') || [] // 防止多窗口覆盖问题
    storage.set(
      'v5_HisNumber',
      _trackHistory.filter((ele) => ele !== num)
    )
    setTrackHistory(trackHistory?.filter((ele) => ele !== num))
    setShowMore(false)
  }

  return (
    <div className={styles.tracking}>
      <section>
        <header className="text-center pt-10">
          <h1 className="text-center font-size-10">{title || $t('ResWwwPHome.global.__track_title')}</h1>
        </header>
        <p className={classNames('text-center', styles['queries-num'])}>
          {desc
            ? i18nFormat(desc, [carriers?.partner, carriers?.airwaybill])
            : i18nFormat($t('ResWwwPHome.global.__airwayTrack_airwayDescTips'), [
                carriers?.partner,
                carriers?.airwaybill
              ])}
        </p>

        {/* 查询表单 */}
        <BatchTrack customPlaceholder={placeholder} />

        {/* 查询历史 */}
        <When condition={trackHistory?.length}>
          <ul className={classNames('flex items-center flex-wrap', styles['track-history'])}>
            <li>
              <span className={styles['text-label']}>{$t('ResWwwPHome.global.__history_trackHistoryText')}：</span>
            </li>
            {trackHistory?.map((ele, idx, arr) => (
              <li key={`${ele + idx}`}>
                <Switch>
                  <Case condition={idx < 2}>
                    <a href="#" onClick={(e) => handleSelHistoryTrack(e, ele)}>
                      <span className="line-clamp-1" title={ele}>
                        {ele}
                      </span>
                      <i className={classNames('fa-times-circle')} onClick={(e) => handleDel(e, ele)}></i>
                    </a>
                  </Case>
                  <Case condition={idx === 2}>
                    <div ref={dropdownRef}>
                      <a className={styles.more} onClick={() => setShowMore((pre) => !pre)}>
                        More <span className={styles.caret}></span>
                      </a>
                      <div
                        ref={ref}
                        className={classNames(styles['dropdown-menu'], { [styles.show]: showMore })}
                        style={{ top: -12 - Number(ref.current?.clientHeight || 0) }}
                      >
                        {arr.slice(2, 10).map((item) => (
                          <div key={`${item + idx}`} className={styles['dropdown-menu-item']}>
                            <a href="#" onClick={(e) => handleSelHistoryTrack(e, item)}>
                              <span className="line-clamp-1" title={item}>
                                {item}
                              </span>
                              <i className={classNames('fa-times-circle')} onClick={(e) => handleDel(e, item)}></i>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Case>
                </Switch>
              </li>
            ))}
          </ul>
        </When>
      </section>

      {/* 广告位 */}
      {showAd ? <section className={classNames('ad-slot', styles['gad-container'])} id="DA_V6-WWW"></section> : null}
      {/* <section className={classNames('ad-slot', styles['gad-container'])} id="DA_V6-WWW">
        <DefineAdSlot {...ADS_CONFIG} />
      </section> */}
      {/* <RequestAds /> */}
    </div>
  )
}

export default memo(Tracking)
