import { useEffect } from 'react'

export default function useRedirectSite() {
  const redirectSite = () => {
    try {
      const localUrl = location.href
      const oriHost = location.host
      const isMobile = window.YQReloadMobile?.isMobile()
      let desHref

      const regUrl = /[\?\#]nums=[\w\.\_]+/
      const isMatchUrl = regUrl.exec(localUrl)

      // 命中M站
      if (isMobile) {
        if (isMatchUrl) {
          // 重定向M站结果页
          const pathName = location.pathname
          const oPath = pathName.match(/^\/[\w-]+/)
          const langPath = oPath?.[0]
          const newPathName = langPath + '/track'

          desHref = '//m.17track.net' + newPathName + location.search + location.hash
        } else {
          // 重定向M站首页
          desHref = localUrl.replace(oriHost, 'm.17track.net')
        }

        window.location.href = desHref
        return
      }

      // 命中T站，带单号的重定向到T站
      if (isMatchUrl) {
        desHref = localUrl.replace(oriHost, 't.17track.net')
        window.location.href = desHref
        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    redirectSite()
  }, [])
}
