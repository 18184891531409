import classNames from 'classnames'
import useI18n from 'src/hooks/use_i18n'
import styles from './index.module.scss'

export default function FreeApply() {
  const { $t } = useI18n()

  const handleRegister = () => {
    window.ga?.sendEvent?.('功能操作', '首页-注册按钮')
  }

  return (
    <section className={styles['free-apply']}>
      <h2 className={classNames(styles.title)} data-animation-in>
        {$t('ResWwwPHome.global.__guidedRegister_title')}
      </h2>
      <p className={classNames(styles.desc)} data-animation-in>
        {$t('ResWwwPHome.global.__guidedRegister_desc')}
      </p>
      <button className="cursor-pointer" data-animation-in onClick={handleRegister}>
        <a href="https://user.17track.net/register">{$t('ResWwwPHome.global.__guidedRegister_guideBtn')} &gt;&gt;</a>
      </button>
    </section>
  )
}
