import { ReactNode, useEffect, useRef } from 'react'

interface Props {
  id: string
  onVisible?: (id: any) => void
  children?: ReactNode
  threshold?: number
}
function GaDecoratorBlock(props: Props) {
  const { id, onVisible, children, threshold } = props
  const ref = useRef(null)
  const hasBeenVisible = useRef(false) // 使用 ref 来存储是否已经触发过

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasBeenVisible.current) {
            onVisible?.(id)
            hasBeenVisible.current = true // 标记为已触发
          }
        })
      },
      { threshold: threshold || 0.3 }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [id, onVisible])

  return <div ref={ref}>{children}</div>
}

export default GaDecoratorBlock
