import jsCookie from 'js-cookie'
import { setDimension } from '../ga/helper'
import YQAD from './config/adapter'
import getAdsConfigs from './config/adconfig'

let AD = {}

module.exports = function (country) {
  AD.divs = ['DA_V6-WWW']
  let curInterval = country === 'CN' ? 240 : Math.random() > 0 ? 120 : 240
  console.log(country, curInterval)
  let googleAds = [
    {
      slotId: '/' + window.YQ.configs.adId + '/V6-WWW',
      divId: 'DA_V6-WWW',
      interval: curInterval,
      mapping: [
        {
          viewSize: [768, 100],
          slotSize: [728, 90]
        },
        {
          viewSize: [468, 100],
          slotSize: [468, 60]
        },
        {
          viewSize: [320, 100],
          slotSize: [320, 50]
        },
        {
          viewSize: [0, 0],
          slotSize: []
        }
      ]
    }
  ]
  let adengineAds = [
    {
      slotId: 'homepage-leaderboard-desktop',
      divId: 'adngin-homepage-leaderboard-desktop-0'
    }
  ]
  let adpushupAds = [
    {
      divId: '75799164-9771-4219-8f44-11a46638ef21',
      interval: 240
    }
  ]

  let adsConfigs = getAdsConfigs()

  console.log('adsConfigs', adsConfigs)

  // 将对应divId进行赋值
  function setDivId(arrAds) {
    arrAds.forEach(function (item, index) {
      if (item.divId !== AD.divs[index]) {
        let divEl = document.getElementById(AD.divs[index])
        divEl?.setAttribute('id', item.divId)
      }
    })
  }

  // 设置广告容器ID
  function setADContainerId(ad) {
    let id = ''
    switch (ad) {
      case 'adengine':
        id = 'adngin-homepage-leaderboard-desktop-0'
        break
      case 'adpushup':
        id = '75799164-9771-4219-8f44-11a46638ef21'
        break
      default:
        id = 'DA_V6-WWW'
        break
    }

    const $el = document.querySelector('.ad-slot')
    $el?.setAttribute('id', id)
    AD.divs = [id]
  }

  YQAD &&
    YQAD.init(adsConfigs, {
      onGoogleLoad: function () {
        console.log('首页 加载了Google广告')

        setADContainerId('google')

        setDivId(googleAds)
        YQAD.google.stopEvent()
        // 添加亚马逊广告标签
        YQAD.google.setTargeting('interval', curInterval + '')
        if (jsCookie.get('isHitAmzAd') === 'true') {
          YQAD.google.setTargeting('pmp_ad', 'amz_ad')
        }
        YQAD.google.initRefresh(googleAds)
        setDimension(6, 'google') // 加载广告维度统计
      },
      onAdengineLoad: function () {
        console.log('首页 加载了Adengine广告')

        setADContainerId('adengine')

        setDivId(adengineAds)

        YQAD.adengine.refresh(
          adengineAds.map(function (item) {
            return item.slotId
          })
        )

        setDimension(6, 'adengine') // 加载广告维度统计
      },
      onAdpushupLoad: function () {
        console.log('首页 加载adpushup广告')

        setADContainerId('adpushup')

        setDivId(adpushupAds)

        YQAD.adpushup.refresh(adpushupAds)
        YQAD.adpushup.setTargeting('adsense', 'clean')
        // 加载广告维度统计
        setDimension(6, 'adpushup')
      }
    })
}
